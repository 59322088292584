import { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from '../redux/actions/auth';

class Logout extends Component {
  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.logout();
      this.reload();
    }
  }

  reload = () => {
    this.props.history.push('/');
    window.location.reload();
  };

  render() {
    return null;
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, { logout })(Logout);
