/**
 * @module actions/auth
 */
import axios from 'axios';
import {
  setAuthorizationToken,
  setDeviceToken,
} from './../../utils/setAuthorizationToken';
import { API_URL, SET_CURRENT_USER } from './../constants';

/**
 * @description Returns payload for dispatching SET_CURRENT_USER
 * @param {Object} user Object containing user data
 */
export function setCurrentUser(user) {
  return {
    type: SET_CURRENT_USER,
    user,
  };
}

/**
 * @description Logs out user by removing localstorage data, removing Axios authorization and dispatching setCurrentUser with empty Object
 */
export function logout() {
  return dispatch => {
    window.localStorage.removeItem('user_token');
    window.localStorage.removeItem('user_data');
    setAuthorizationToken(false);
    setDeviceToken(false);
    dispatch(setCurrentUser({}));
  };
}

/**
 * @description Logs in user by adding localstorage data, setting Axios authorization and dispatching setCurrentUser with user object
 * @param  {Object} data Object that will be posted to url
 */
export function login(data) {
  return dispatch => {
    window.localStorage.clear();
    return axios.post(API_URL + '/auth/login', data).then(res => {
      const token = res.data.token;
      const data = JSON.stringify(res.data);
      window.localStorage.setItem('user_token', token);
      window.localStorage.setItem('user_data', data);

      setAuthorizationToken(token);
      setDeviceToken(res.data.DeviceToken);
      dispatch(setCurrentUser(res.data));

      return res;
    });
  };
}

/**
 * @description Changes the password for user
 */
export function changePassword(data) {
  let url = API_URL + '/auth/ChangePassword';
  return dispatch => {
    return axios.post(url, data);
  };
}
