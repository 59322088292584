// import withBadge from 'hocs/withBadge';
import React from 'react';
import { MdClearAll } from 'react-icons/md';
import { Button, Nav, Navbar } from 'reactstrap';
import Typography from 'components/Typography';
import bn from 'utils/bemnames';
import { connect } from 'react-redux';
import { setLanguage } from './../../redux/actions/common';

const bem = bn.create('header');

// // TODO: MOVE TO utils
// const MdNotificationsActiveWithBadge = withBadge({
//   size: 'md',
//   color: 'primary',
//   style: {
//     top: -10,
//     right: -10,
//     display: 'inline-flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//   },
//   children: <small>5</small>,
// })(MdNotificationsActive);

class Header extends React.Component {
  state = {
    isOpenNotificationPopover: false,
    isNotificationConfirmed: false,
    isOpenUserCardPopover: false,
  };

  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true });
    }
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  // Set language and rerender
  changeLanguage = lang => {
    this.props.setLanguage(lang);
    window.location.reload();
  };

  render() {
    let greetings = null;
    if (!this.props.auth.isAuthenticated) {
      return null;
    }

    if (this.props.auth.user.user) {
      greetings = (
        <Nav navbar>
          <Typography type="h5">
            {this.props.auth ? `Hej, ${this.props.auth.user.user.name}` : null}
          </Typography>
        </Nav>
      );
    }
    return (
      <Navbar light expand className={bem.b('bg-white')}>
        <Nav navbar className="mr-2">
          <Button outline onClick={this.handleSidebarControlButton}>
            <MdClearAll size={25} />
          </Button>
        </Nav>
        {greetings}
      </Navbar>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(
  mapStateToProps,
  { setLanguage },
)(Header);
