import React from 'react';
import { Input, Label, FormGroup, Col } from 'reactstrap';

/**
 * Custom text-input with ability to have a label displayed to its left.
 * @param {*} props - set properies here
 * @param {Boolean} row
 * @param {Boolean} disabled
 * @param {Number} labelSize
 * @param {Number} inputSize
 * @param {String} for
 * @param {String} type
 * @param {String} name
 * @param {*} value
 * @param {Function} onChange
 * @param {Function} onBlur
 * @param {String} placeholder
 * @param {Boolean} error - Bind to a boolean value
 * @param {String} errorText - Displayed text when error is true
 */
const TextInput = props => {
  let lineRows = 3;
  if (props.value && typeof props.value.toLowerCase() === 'string') {
    lineRows = props.value.split('\n').length;

    if (lineRows < 3) lineRows = 3;
    if (lineRows > 12) lineRows = 12;
  }

  if (props.row) {
    return (
      <FormGroup row>
        <Label sm={props.labelSize} for={props.for}>
          {props.for}
        </Label>
        <Col sm={props.inputSize}>
          <Input
            type={props.type ? props.type : 'text'}
            name={props.name}
            value={props.value ? props.value : ''}
            onChange={props.onChange}
            onBlur={props.onBlur}
            placeholder={props.placeholder}
            disabled={props.disabled}
            bsSize='md'
            rows={props.rows ? props.rows : lineRows}
          />
          {props.extra}
          {props.error ? (
            <p style={{ color: 'red' }}>
              {props.error ? props.errorText : null}
            </p>
          ) : null}
        </Col>
      </FormGroup>
    );
  }

  return (
    <FormGroup>
      <Label for={props.for}>{props.for}</Label>
      <Input
        type={props.type ? props.type : 'text'}
        name={props.name}
        value={props.value ? props.value : ''}
        onChange={props.onChange}
        onBlur={props.onBlur}
        placeholder={props.placeholder}
        disabled={props.disabled}
        rows={props.rows ? props.rows : lineRows}
      />
      {props.extra}
      {props.error ? (
        <p style={{ color: 'red' }}>{props.error ? props.errorText : null}</p>
      ) : null}
    </FormGroup>
  );
};

export default TextInput;
