import React from 'react';
import { Button, Form } from 'reactstrap';
import PropTypes from 'prop-types';

import TextInput from '../components/Custom/Forms/TextInput';
import PageSpinner from '../components/PageSpinner';

import translation from '../utils/translation';
import { StringIsEmpty } from '../utils/common';

import ItpilotLogo from './../assets/img/logo/itpilot-logo.png';

class AuthForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      missingUsername: false,
      missingPassword: false,
    };
  }

  handleSubmit = event => {
    event.preventDefault();
    const { username, password } = this.state;

    let emptyUsername = StringIsEmpty(username);
    let emptyPassword = StringIsEmpty(password);

    if (!emptyUsername)
      emptyUsername = StringIsEmpty(username.replace(' ', ''));
    if (!emptyPassword)
      emptyPassword = StringIsEmpty(password.replace(' ', ''));

    if (emptyUsername || emptyPassword) {
      this.setState({
        missingUsername: emptyUsername,
        missingPassword: emptyPassword,
        username: '',
        password: '',
      });
    } else {
      this.props.login(username, password);
    }
  };

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      missingUsername: false,
      missingPassword: false,
    });
  };

  render() {
    const {
      showLogo,
      usernameLabel,
      passwordLabel,
      children,
      loginFailed,
      showSpinner,
    } = this.props;

    const { missingUsername, missingPassword } = this.state;

    return (
      <Form onSubmit={this.handleSubmit}>
        {showLogo && (
          <div className='text-left pb-4'>
            <img src={ItpilotLogo} className='authform_logo' alt='Itpilot' />
          </div>
        )}

        <TextInput
          for={usernameLabel}
          placeholder={translation(14)}
          name='username'
          onChange={this.onChange}
          error={missingUsername}
          errorText={translation(32)}
          value={this.state.username}
          disabled={showSpinner}
        />

        <TextInput
          for={passwordLabel}
          placeholder={translation(15)}
          name='password'
          type='password'
          onChange={this.onChange}
          error={missingPassword}
          errorText={translation(33)}
          value={this.state.password}
          disabled={showSpinner}
        />

        <hr />
        {loginFailed ? (
          <p style={{ color: 'red' }}>{this.props.errorText}</p>
        ) : null}

        {showSpinner ? <PageSpinner /> : null}
        {!showSpinner ? (
          <Button size='lg' block color='primary' type='submit'>
            {translation(8)}
          </Button>
        ) : null}

        {children}
      </Form>
    );
  }
}

AuthForm.propTypes = {
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  passwordLabel: PropTypes.string,
};

AuthForm.defaultProps = {
  showLogo: true,
  usernameLabel: translation(14),
  passwordLabel: translation(15),
};

export default AuthForm;
