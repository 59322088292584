import {
  MdMessage,
  MdKeyboardTab,
  MdInsertChart,
  MdBusiness,
} from 'react-icons/md';
import translation from 'utils/translation';

export const hasMinAccessLevel = (auth, minLevel, reverse = false) => {
  // 1 - Super, 2 - Local, 3 - Responsible

  if (auth.loginAsUser) {
    const _user = JSON.parse(auth.loginAsUser);

    if (reverse) {
      return _user.loginData.accessLevel >= minLevel;
    }
    return _user.loginData.accessLevel <= minLevel;
  } else {
    if (reverse) {
      return auth.user.accessLevel >= minLevel;
    }
    return auth.user.accessLevel <= minLevel;
  }
};

export const getLoginAsUserData = auth => {
  let data = null;

  if (auth) {
    if (auth.loginAsUser) {
      data = auth.loginAsUser;
    }
  }

  return data;
};

export const isAllowedAccess = (user, featureNumber) => {
  let allowed = false;

  if (user) {
    const features = user.accessableFeatures.split(',');
    const found = features.find(feat => feat === `${featureNumber}`);

    if (found) {
      allowed = true;
    }
  }

  return allowed;
};

export const sidebarRoutes = (
  accessLevel,
  isLogoutAs,
  isLogoutName,
  accessableFeatures,
) => {
  const navItems = [];

  navItems.push(
    {
      to: '/customers',
      name: translation(5),
      exact: true,
      Icon: MdBusiness,
    },
    {
      to: '/messages',
      name: translation(35),
      exact: true,
      Icon: MdMessage,
    },
    {
      to: '/statistics',
      name: translation(37),
      exact: true,
      Icon: MdInsertChart,
    },
  );

  // Logout is always final item
  navItems.push({
    to: '/logout',
    name: translation(9),
    exact: true,
    Icon: MdKeyboardTab,
    isLogoutAs: isLogoutAs,
  });

  return navItems;
};
