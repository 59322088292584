import moment from 'moment';
import { parse } from 'date-fns';
import { hasLetter, hasNumber, hasMin8Chars } from './passwordValidator';

function reverseDate(date) {
  let arr = date.split('-');
  return `${arr[2]}-${arr[1]}-${arr[0]}`;
}

export const parseDate = (date, showTime = false) => {
  if (!showTime) {
    return moment(date).format('DD-MM-YYYY');
  } else {
    return `${moment(date).format('DD-MM-YYYY')}, kl. ${moment(date).format(
      'H:mm',
    )}`;
  }
};

export const parseDateNET = date => {
  return moment(date).format('MM-DD-YYYY');
};

export const parseDateRFC2822 = date => {
  const normal = parseDate(date);
  return parse(normal, 'dd-MM-yyyy', new Date());
};

export const dateIsBetween = (date, start, end) => {
  if (start === 'Invalid date' || end === 'Invalid date') return true;
  return moment(reverseDate(date)).isBetween(
    reverseDate(start),
    reverseDate(end),
    null,
    '[]',
  );
};

export const splitText = (text, firstPart, secondPart) => {
  if (text.length >= 17) {
    return `${firstPart}-\n${secondPart}`;
  }
  return text;
};

export const StringIsEmpty = str => {
  return !str || 0 === str.length;
};

export const ValidPhone = str => {
  let valid = false;

  if (!StringIsEmpty(str)) {
    if (!hasLetter.test(str)) {
      if (hasNumber.test(str)) {
        valid = hasMin8Chars.test(str);
      }
    }
  }

  return valid;
};

export const formatNumber = num => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
};
