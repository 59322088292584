// API URL
export const API_URL = process.env.REACT_APP_API_URL;

// Auth constants
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_LOGIN_AS = 'SET_LOGIN_AS';
export const LOGOUT_AS = 'LOGOUT_AS';

// Customer constants
export const SET_CUSTOMERS = 'SET_CUSTOMERS';
export const SET_CUSTOMER = 'SET_CUSTOMER';

// SMS/Message constans
export const SET_MESSAGES = 'SET_MESSAGES';

// Common constants
export const SET_USER_LANGUAGE = 'SET_USER_LANGUAGE';
