// // Opera 8.0+
// const isOpera =
//   (!!window.opr && !!opr.addons) ||
//   !!window.opera ||
//   navigator.userAgent.indexOf(' OPR/') >= 0;

// // Firefox 1.0+
// const isFirefox = typeof InstallTrigger !== 'undefined';

// // Safari 3.0+ "[object HTMLElementConstructor]"
// const isSafari =
//   /constructor/i.test(window.HTMLElement) ||
//   (function(p) {
//     return p.toString() === '[object SafariRemoteNotification]';
//   })(
//     !window['safari'] ||
//       (typeof safari !== 'undefined' && safari.pushNotification),
//   );

// Internet Explorer 6-11
const isIE = /*@cc_on!@*/ false || !!document.documentMode;

// // Edge 20+
// const isEdge = !isIE && !!window.StyleMedia;

// // Chrome 1 - 79
// const isChrome =
//   !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

// // Edge (based on chromium) detection
// const isEdgeChromium = isChrome && navigator.userAgent.indexOf('Edg') != -1;

// // Blink engine detection
// const isBlink = (isChrome || isOpera) && !!window.CSS;

export function isBrowser(type) {
  if (!type) return false;
  if ((typeof type).toLowerCase() !== 'string') return false;

  const browser = type.toLowerCase();

  switch (browser) {
    // case 'opera':
    //   return isOpera;
    // case 'mozilla':
    // case 'firefox':
    //   return isFirefox;
    // case 'safari':
    //   return isSafari;
    case 'ie':
      return isIE;
    // case 'edge':
    //   return isEdge;
    // case 'chrome':
    //   return isChrome;
    // case 'edgechromium':
    //   return isEdgeChromium;
    // case 'blink':
    //   return isBlink;
    default:
      return false;
  }
}
