// if in english, translate to corresponding language

export const dk = {
  1: 'Bekræft',
  2: 'Annullér',
  3: 'Forside',
  4: 'Kunde',
  5: 'Kunder',
  6: 'Bruger',
  7: 'Brugere',
  8: 'Login',
  9: 'Log ud',
  10: 'Navn',
  11: 'Rediger',
  12: 'Slet',
  13: 'Opret',
  14: 'Brugernavn',
  15: 'Adgangskode',
  16: 'E-mail',
  17: 'Mobil',
  18: 'Telefon',
  19: 'Opdater',
  20: 'Statistik',
  21: 'Sletter',
  22: 'Søg',
  23: 'Åben',
  24: 'Ingen',
  25: 'Mail',
  26: 'Tilføj',
  27: 'Kontakt telefon',
  28: 'Kontakt mail',
  29: 'Status',
  30: 'Aktiv',
  31: 'Inaktiv',
  32: 'Mangler brugernavn',
  33: 'Mangler adgangskode',
  34: 'Token',
  35: 'Beskeder',
  36: 'Vis statistik',
  37: 'Statistik',
  38: 'Tekst',
  39: 'Dato',
  40: 'Modtager',
  41: 'Kunde',
  42: 'Guid',
  43: 'Besked',
  44: 'Mere end 1',
  45: 'Ja',
  46: 'Nej',
  47: 'Hvis',
  48: 'Sendt',
  49: 'Ikke sendt',
  50: 'Id',
  51: 'Sekvensnummer',
  52: 'Tilhørende logged beskeder',
  53: 'Total',
  54: 'Antal',
  55: 'År',
  56: 'Måned',
  57: 'Dag',
  58: 'Periode',
  59: 'Kun de seneste 1000 beskeder returneres',
  60: 'Ingen valgt',
  61: 'Periode type',
  62: 'Kvartal',
  63: 'Januar',
  64: 'Februar',
  65: 'Marts',
  66: 'April',
  67: 'Maj',
  68: 'Juni',
  69: 'Juli',
  70: 'August',
  71: 'September',
  72: 'Oktober',
  73: 'November',
  74: 'December',
  75: 'SMSer sendt i alt',
  76: 'I alt',
  77: 'Time interval',
  78: 'Vis søge filter',
  79: 'Skjul søge filter',
  80: 'Detaljer',
  81: 'Alle',
};
