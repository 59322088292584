import { dk } from './langs/dk';

export const languages = [
  { name: 'Dansk', lang: 'dk' },
];

export default function translate(txtId) {
  // const langId = window.localStorage.getItem('user_lang');
  const langId = 'dk';
  switch (langId) {
    case 'dk':
      return dk[txtId];
    default:
      return dk[txtId];
  }
}
