import { SET_USER_LANGUAGE } from '../constants';

const initialState = {
  user_lang: 'eng',
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_USER_LANGUAGE:
      return {
        ...state,
        user_lang: action.user_lang,
      };
    default:
      return state;
  }
};
