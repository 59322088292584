import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
import PageSpinner from 'components/PageSpinner';
import React from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import axios from 'axios';

// Page Imports
import LoginPage from './pages/Login';
import LogoutPage from './pages/Logout';

// Style
import './styles/reduction.scss';

// Redux
import {
  setAuthorizationToken,
  setDeviceToken,
} from './utils/setAuthorizationToken';
import { setCurrentUser } from './redux/actions/auth';
import { ConnectedRouter } from 'connected-react-router';
import configureStore, { history } from './redux/store';
import { logout } from './redux/actions/auth';

const initialState = {};
const store = configureStore(initialState);

// Pre-check of login-status - Tokens and user data
const usertoken = localStorage.getItem('user_token');
const userdata = localStorage.getItem('user_data');
if (usertoken && userdata) {
  setAuthorizationToken(usertoken);
  setDeviceToken(JSON.parse(userdata).deviceToken);

  store.dispatch(setCurrentUser(JSON.parse(userdata)));
}

// Axios response interceptor - Logout on 401
axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response.status === 401) {
      store.dispatch(logout());
      if (window.location.pathname !== '/login') {
        history.push('/');
        window.location.reload(true);
      }
    }
    return Promise.reject(error);
  },
);

// Page lazyloading (code-splitting --> quicker loading, efficiency)
// const DashboardPage = React.lazy(() => import('./pages/DashboardPage'));
const CustomersPage = React.lazy(() => import('./pages/CustomerPage'));
const MessagesPage = React.lazy(() => import('./pages/Messages'));
const StatisticsPage = React.lazy(() => import('./pages/Statistics'));

class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <BrowserRouter>
            <Switch>
              <LayoutRoute
                exact
                path='/login'
                layout={EmptyLayout}
                component={props => <LoginPage {...props} />}
              />
              <LayoutRoute
                exact
                path='/logout'
                layout={EmptyLayout}
                component={props => <LogoutPage {...props} />}
              />
              <MainLayout breakpoint={this.props.breakpoint} history={history}>
                <React.Suspense fallback={<PageSpinner />}>
                  <Route
                    exact
                    path='/'
                    component={props => <CustomersPage {...props} />}
                  />
                  <Route
                    exact
                    path='/customers'
                    component={props => <CustomersPage {...props} />}
                  />
                  <Route
                    exact
                    path='/messages'
                    component={props => <MessagesPage {...props} />}
                  />
                  <Route
                    exact
                    path='/statistics'
                    component={props => <StatisticsPage {...props} />}
                  />
                </React.Suspense>
              </MainLayout>
              <Redirect to='/' />
            </Switch>
          </BrowserRouter>
        </ConnectedRouter>
      </Provider>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    // console.log('bp xs', width);
    return { breakpoint: 'xs' };
  }

  if (575 <= width && width < 767) {
    // console.log('bp sm', width);
    return { breakpoint: 'sm' };
  }

  if (767 <= width && width < 991) {
    // console.log('bp md', width);
    return { breakpoint: 'md' };
  }

  if (991 <= width && width < 1200) {
    // console.log('bp lg', width);
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    // console.log('bp xl', width);
    return { breakpoint: 'xl' };
  }

  // console.log('bp xs d', width);
  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
