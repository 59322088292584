import AuthForm from '../components/AuthForm';
import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { login } from '../redux/actions/auth';
import translation from '../utils/translation';
import { osName } from 'react-device-detect';
import { isBrowser } from '../utils/browserCheck';

const isIE = isBrowser('ie');

class AuthPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      failedLogin: false,
      errorText: null,
      showSpinner: false,
    };
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/');
    }
  }

  handleLogoClick = () => {
    window.location.href = 'https://www.itpilot.dk/';
  };

  handleLogin = (username, password) => {
    this.setState({ showSpinner: true }, () => {
      this.props
        .login({
          username: username,
          password: password,
          devicetype: osName,
        })
        .then(res => {
          this.setState({ showSpinner: false }, () => {
            this.props.history.push('/');
          });
        })
        .catch(err => {
          let errorText = translation(1090);
          if (err.response.data) {
            if (err.response.data.dictionaryKey) {
              errorText = translation(err.response.data.dictionaryKey);
            }
          }
          this.setState({ failedLogin: true, showSpinner: false, errorText });
        });
    });
  };

  renderUnsupportedBrowser() {
    if (isIE) {
      return (
        <div className='unsupported-container'>
          <p>
            {translation(1277)}
            <a href='https://www.microsoft.com/en-us/edge'>Edge</a>,
            <a href='https://www.google.com/chrome/'>Chrome</a>,
            <a href='https://www.apple.com/safari/'>Safari</a>
            {` `}
            {translation(1278).toLowerCase()}
            <a href='https://www.mozilla.org/da/firefox/new/'>Firefox</a>
          </p>
        </div>
      );
    }
  }

  render() {
    if (isIE) {
      return this.renderUnsupportedBrowser();
    }

    return (
      <Row
        style={{
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Col md={6} lg={4}>
          <Card body>
            <AuthForm
              onLogoClick={this.handleLogoClick}
              login={this.handleLogin}
              loginFailed={this.state.failedLogin}
              errorText={this.state.errorText}
              showSpinner={this.state.showSpinner}
            />
          </Card>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {
  login,
})(AuthPage);
