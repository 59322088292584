/**
 * @module actions/common
 */
import axios from 'axios';
import { API_URL, SET_USER_LANGUAGE } from '../constants';

/**
 * @description Change language
 * @return {Promise} Promise
 */
export function setLanguage(language) {
  return dispatch => {
    window.localStorage.setItem('user_lang', language);
    dispatch({
      type: SET_USER_LANGUAGE,
      user_lang: language,
    });
  };
}

/**
 * @description Change language
 * @return {Promise} Promise
 */
export function getStatisticOptions() {
  let url = API_URL + `/sms/options`;
  return dispatch => {
    return axios.get(url);
  };
}
