import logo_itpilot from '../../assets/img/logo/itpilot-logo.png';
import sidebarBgImage from '../../assets/img/sidebar/sidebar-4.jpg';
import SourceLink from 'components/SourceLink';
import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  // UncontrolledTooltip,
  // Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from '../../utils/bemnames';
import { connect } from 'react-redux';
import { logout } from './../../redux/actions/auth';
import { sidebarRoutes } from './../../utils/contentByAccessLevel';
import { getColor } from '../../utils/colors';

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  state = {
    backgroundColor: null,
    logo: null,

    isOpenComponents: true,
    isOpenContents: true,
    isOpenPages: true,
    navItems: [],
  };

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      let user_data,
        isLoginAs = false,
        isLoginAsName;
      user_data = JSON.parse(localStorage.getItem('user_data'));

      this.setState({
        navItems: sidebarRoutes(
          user_data.accessLevel,
          isLoginAs,
          isLoginAsName,
          user_data.accessableFeatures,
        ),
      });
    } else {
      this.props.history.push('/login');
      window.location.reload(); // <--- Page does not update on push, forcing reload
    }
  }

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  renderNavItem = ({ to, name, exact, Icon }, index) => {
    return (
      <NavItem key={index} className={bem.e('nav-item')}>
        <BSNavLink
          id={`navItem-${name}-${index}`}
          className='text-uppercase'
          tag={NavLink}
          to={to}
          activeClassName='active'
          exact={exact}
          active={true}
        >
          <Icon className={bem.e('nav-item-icon')} />
          <span className=''>{name}</span>
        </BSNavLink>
      </NavItem>
    );
  };

  render() {
    const { logo, backgroundColor, navItems } = this.state;

    if (!this.props.auth.isAuthenticated) {
      return <aside className={bem.b()} data-image={sidebarBgImage} />;
    }

    let bgColor = backgroundColor || getColor('bgPrimary');

    const regNavItems = navItems.filter(ni => !ni.isBottom);
    const specialNavItems = navItems.filter(ni => ni.isBottom);
    return (
      <aside
        className={bem.b()}
        data-image={sidebarBgImage}
        style={{ background: bgColor }}
      >
        <div className={bem.e('background')} />

        <div className={bem.e('content')}>
          <Navbar>
            <SourceLink
              source='/customers'
              className='navbar-brand d-flex sidebar-navbar-brand'
            >
              <img
                src={logo ? logo.fileUrl : logo_itpilot}
                alt={logo ? logo.originalFileName : ''}
                className='cr-sidebar__logo'
              />
            </SourceLink>
          </Navbar>
          <Nav vertical>
            {regNavItems.map(this.renderNavItem)}
            <div className='cr-sidebar__divider' />
            {specialNavItems.map(this.renderNavItem)}
          </Nav>
        </div>
      </aside>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, { logout })(Sidebar);
